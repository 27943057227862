import { Box, useDisclosure } from '@chakra-ui/react';
import EditModal from '~/profile/components/experiences/internal/EditModal';
import InternalExperienceParent from '~/profile/components/experiences/internal/InternalExperienceParent';
import { ProfileSectionHeader } from '~/profile/components/ProfileSectionHeader';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import useInternalExperience from '~/shared/hooks/employee/useEmployeeInternalExperiences';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeInternalExperience } from '~/shared/models/api/employee';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const InternalExperiences: React.FC = () => {
  const { profile, removeInternalExperienceFromLocalStore } = useProfileStore();
  const { t } = useTranslation(['base', 'profile']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedExperience, setSelectedExperience] =
    useState<EmployeeInternalExperience>();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { deleteInternalExperience } = useInternalExperience();

  const openModal = (experience?: EmployeeInternalExperience) => {
    setSelectedExperience(experience);
    onOpen();
  };

  const closeModal = () => {
    setSelectedExperience(undefined);
    onClose();
  };

  const deleteExperience = async (experience: EmployeeInternalExperience) => {
    if (!profile) {
      return;
    }

    startLoading();
    try {
      await deleteInternalExperience.mutateAsync(experience);
      removeInternalExperienceFromLocalStore(experience);
    } finally {
      stopLoading();
    }
  };

  const handleDelete = async (experience: EmployeeInternalExperience) => {
    const result = await openConfirmation({
      isDangerAction: true,
      titleText: experience.project.title,
      descriptionText: t(`base:delete_confirmation`),
      confirmText: t(`base:delete`),
    });

    if (result) {
      await deleteExperience(experience);
    }
  };

  return (
    <Box data-testid="profile-component-experiences">
      {isOpen && (
        <EditModal experience={selectedExperience} onClose={closeModal} />
      )}
      <ProfileSectionHeader
        headingDataTestId="test-heading-experiences"
        onClickAdd={openModal}
        sectionTitle={t(`base:company_projects`)}
      />
      {!profile?.ki_projects?.length ? (
        <MissingData text={t(`profile:project.not_added`)} />
      ) : (
        <Box rounded={12}>
          {profile?.ki_projects?.map((ki_project, index) => (
            <InternalExperienceParent
              experience={ki_project}
              key={`internal-experience-parent-${index}-id-${ki_project.project.id}`}
              onDelete={handleDelete}
              onOpen={openModal}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default InternalExperiences;
