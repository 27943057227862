import { Box, Text } from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import { CustomTag } from '~/shared/components/ui/CustomTag';
import useSkillCreate from '~/shared/hooks/skills/useSkillCreate';
import { mapValueToOption } from '~/shared/mappers/selectOption';
import { Skill } from '~/shared/models/api/skills';
import { SelectOption } from '~/shared/models/react-select/select-option';
import { useSkillsSearch } from '~/shared/queries/skills/useSkillsSearch';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

type SkillType = number | string;
type SkillValue = SkillType | SkillType[];
type SkillOption = SelectOption<SkillType>;

interface Props {
  id?: string;
  isClearable?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  onChange?: (value?: SkillValue) => void;
  placeholder?: string;
  returnValue?: 'id' | 'name';
  selectedSkills?: string[];
}

const SkillSelectByValue: FC<Props> = ({
  id = 'skill-select',
  isClearable = true,
  isCreatable = true,
  isDisabled,
  isMulti,
  onChange,
  placeholder,
  returnValue = 'name',
  selectedSkills = [],
}) => {
  const [selected, setSelected] = useState<SkillOption | SkillOption[] | null>(
    isMulti ? [] : null
  );
  const [options, setOptions] = useState<SkillOption[]>();
  const [query, setQuery] = useState<string>('');

  const skillsSearch = useSkillsSearch({ query });
  const { createSkill } = useSkillCreate();
  const { profile } = useProfileStore();
  const { t } = useTranslation('skills');

  const parseValueToOption = (skill: SkillValue) =>
    mapValueToOption<SkillType>(skill as SkillType);

  const changeMultipleOptions = (options: SelectOption<SkillType>[]) =>
    onChange && onChange(options.map((o) => o.value));

  const changeSingleOption = (option: SelectOption<SkillType>) =>
    onChange && onChange(option?.value || undefined);

  const handleChange = (
    selected: MultiValue<SkillOption> | SingleValue<SkillOption>
  ) => {
    if (isMulti) {
      const selectedOptions = Array.isArray(selected) ? selected : [selected];
      changeMultipleOptions(selectedOptions as SelectOption<SkillType>[]);
    } else {
      changeSingleOption(selected as SelectOption<SkillType>);
    }
    setSelected(selected as SelectOption<SkillType>);
  };

  const addSkill = (skillValue: SkillValue) => {
    const skillOption = parseValueToOption(skillValue);

    setSelected((prev) => {
      if (Array.isArray(prev)) {
        return [...prev, skillOption];
      }

      if (prev) {
        return [prev, skillOption];
      }

      return [skillOption];
    });
  };

  const setSavedSkills = (selected: SkillValue) => {
    if (isMulti) {
      const skills = selected as SkillType[];
      const selectedOptions = skills.map(parseValueToOption);
      setSelected(selectedOptions);
    } else {
      addSkill(selected);
    }
  };

  const handleAddQuickSkillClick = (skillName: string) => {
    addSkill(skillName);

    const optionSkill = mapValueToOption<SkillType>(skillName);
    const changedOptions = Array.isArray(selected)
      ? [...selected, optionSkill]
      : optionSkill;
    handleChange(changedOptions);
  };

  const handleAddSkillByName = (skillName: string) => {
    createSkill.mutateAsync(
      { name: skillName },
      {
        onSuccess: (skill: Skill) => {
          const mappedSkill = mapValueToOption<SkillType>(skill[returnValue]);

          if (isMulti) {
            handleChange([
              ...(selected as SelectOption<SkillType>[]),
              mappedSkill,
            ]);

            return;
          }

          handleChange(mappedSkill);
        },
      }
    );
  };

  const filterQuickSkillsSelection = useCallback(
    () =>
      profile?.main_skills.filter(
        ({ skill }) =>
          Array.isArray(selected) &&
          selected.find(({ value }) => value === skill.name) === undefined
      ) ?? [],
    [profile?.main_skills, selected]
  );

  useEffect(() => {
    if (selectedSkills.length && isMulti) {
      setSavedSkills(selectedSkills);
    }
  }, [selectedSkills, isMulti]);

  useEffect(() => {
    if (!skillsSearch.data) {
      return;
    }
    const selectOptions = skillsSearch.data.map((skill) =>
      mapValueToOption<SkillType>(skill[returnValue] as string)
    );

    setOptions(selectOptions);
  }, [skillsSearch.data]);

  return (
    <>
      <CreatableSelect
        id={id}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={(!!query && skillsSearch.isLoading) || createSkill.isLoading}
        isMulti={isMulti}
        isOptionDisabled={(option) =>
          selectedSkills?.some((skill) => skill === option.value) || false
        }
        isValidNewOption={() => isCreatable && !!query}
        onChange={handleChange}
        onCreateOption={handleAddSkillByName}
        onInputChange={(query) => setQuery(query)}
        options={options}
        placeholder={placeholder}
        value={selected}
      />
      {filterQuickSkillsSelection().length ? (
        <Box
          bg="gray.50"
          mt={1}
          p={1}
          rounded={4}
          shadow="sm"
          borderColor="gray.200"
          borderWidth="1px"
        >
          <Text color="gray.800" fontSize="xs" fontStyle="italic" minW="115px">
            {t(`quick_skill_select`)}
          </Text>
          {filterQuickSkillsSelection().map(({ skill_id, skill }) => (
            <CustomTag
              key={`profile-skill-${skill_id}`}
              colorScheme="green"
              m={1}
              onClick={() => handleAddQuickSkillClick(skill.name)}
              shadow="sm"
            >
              {skill.name}
            </CustomTag>
          ))}
        </Box>
      ) : null}
    </>
  );
};

export default SkillSelectByValue;
