import { PptColorEnum, PptMetadataEnum } from './enums';
import {
  Ppt,
  PptFillAvatarProps,
  PptPlaceholderProps,
  PptPositionProps,
  PptShapeProps,
  PptSlideMasterObjectsProps,
  PptSquareObject,
  PptTextObject,
  PptTextProps,
} from './types';
import { loadAzureBlob } from '~/shared/api/loadAzureBlob';
import {
  LanguageProficiency,
  LanguageProficiencyText,
} from '~/shared/enums/language';
import {
  Employee,
  EmployeeExternalExperience,
  EmployeeInternalExperience,
  EmployeeSkill,
} from '~/shared/models/api/employee';
import { orderEmployeeLanguagesByProficiency } from '~/shared/utils/languages';

const sanitizeHtml = (text: string) =>
  text
    .replace(/\u200B/g, '') // Zero-width space
    .replace(/\u200C/g, '') // Zero-width non-joiner
    .replace(/\u200D/g, '') // Zero-width joiner
    .replace(/\uFEFF/g, '') // Zero-width no-break space
    .replace(/\u00A0/g, ' ') // Non-breaking space
    .replace(/\u2028/g, '') // Line separator
    .replace(/\u2029/g, '') // Paragraph separator
    .replace('\u000B', '') // Vertical Tab
    .replace('\t', '') // Tab
    .replace('\n', ''); // New Line

const replaceHtmlElements = (text: string) =>
  text
    .replace(/<a [^>]*href="([^"]+)"[^>]*>(.*?)<\/a>/gi, `"$2"($1)`) // Replace <a href> tags with the text and URL in parentheses
    .replace(/<ul>/gi, '\n') // Replace <ul> with a new line
    .replace(/<ol>/gi, '\n') // Replace <ol> with a new line
    .replace(/<\/ul>/gi, '') // Remove </ul>
    .replace(/<\/ol>/gi, '') // Remove </ol>
    .replace(/<li>/gi, '  - ') // Replace <li> with "  - "
    .replace(/<\/li>/gi, '\n') // Replace </li> with a new line
    .replace(/<br\s*\/?>/gi, '\n') // Replace <br> with a new line
    .replace(/<\/p>/gi, '\n') // Replace </p> with a new line
    .replace(/<\/?[^>]+(>|$)/g, '') // Remove all other HTML tags
    .replace(/\n\s*\n/g, '\n') // Remove extra blank lines
    .trim(); // Trim leading and trailing whitespace

export const stripHtml = (html: string): string => {
  const sanitizedHtml = sanitizeHtml(html);

  return replaceHtmlElements(sanitizedHtml);
};

const formatDate = (date: string) => {
  const splittedDate = date.split('-');

  return `${splittedDate[1]}/${splittedDate[0]}`;
};

const buildDateText = (start: string, end?: string | null) =>
  !end
    ? `Since ${formatDate(start)}`
    : `${formatDate(start)} - ${formatDate(end)}`;

export const buildSkillsText = (skills: EmployeeSkill[]): string =>
  skills
    .sort((a, b) => b.skill_level_id - a.skill_level_id)
    .map((s) => `${s.skill.name} ${s.skill_level_id}`)
    .join('\n');

export const buildRectangle = (
  positionProps: PptPositionProps,
  color: PptColorEnum,
  rectProps?: PptShapeProps
): { rect: PptShapeProps } => ({
  rect: {
    ...rectProps,
    ...positionProps,
    fill: { color, ...rectProps?.fill },
  },
});

export const addRectangle = (
  positionProps: PptPositionProps,
  color: PptColorEnum
) => buildRectangle(positionProps, color);

export const addSquare = (
  { size, ...position }: PptSquareObject,
  color: PptColorEnum
) => addRectangle({ ...position, w: size, h: size }, color);

export const addLine = (
  positionProps: PptPositionProps
): { line: PptShapeProps } => ({
  line: {
    ...positionProps,
    line: {
      color: PptColorEnum.GRAY,
      width: 1,
    },
  },
});

export const addImage = (path: string, position: PptPositionProps) => ({
  image: { ...position, path },
});

export const buildText = ({
  text,
  position,
  ...rest
}: PptPlaceholderProps): PptTextObject => ({
  text: { text, options: { ...position, ...rest } },
});

export const addText = (text: string, props: PptPlaceholderProps) =>
  buildText({ ...props, text });

export const addMasterSlide = (
  objects: PptSlideMasterObjectsProps,
  ppt: Ppt,
  title: string
) => {
  ppt.defineSlideMaster({
    title,
    background: { color: PptColorEnum.WHITE },
    objects: objects,
  });
};

export const addMetadata = (ppt: Ppt, title: string) => {
  ppt.author = PptMetadataEnum.AUTHOR;
  ppt.company = PptMetadataEnum.COMPANY;
  ppt.layout = PptMetadataEnum.LAYOUT;
  ppt.revision = '1';
  ppt.subject = PptMetadataEnum.SUBJECT;
  ppt.theme = { headFontFace: 'Montserrat', bodyFontFace: 'Montserrat' };
  ppt.title = title;
};

const buildInternalExperiencesDescription = (
  experiences: EmployeeInternalExperience[] = []
) => {
  if (!experiences.length) {
    return '';
  }

  try {
    return experiences.map((e) => stripHtml(e.tasks)).join('\n');
  } catch (error) {
    console.warn(`Fail generating KI Experiences Description: ${error}`);
    return 'Fail generating KI Experiences';
  }
};

const buildExternalExperiencesDescription = (
  experiences: EmployeeExternalExperience[] = []
) => {
  if (!experiences.length) {
    return '';
  }

  try {
    return experiences.map((e) => stripHtml(e.description)).join('\n');
  } catch (error) {
    console.warn(
      `Fail generating Outside KI Experiences Description: ${error}`
    );

    return 'Fail generating Outside KI Experiences';
  }
};
export const buildDescription = (profile: Employee): string => {
  if (profile.short_description) {
    return profile.short_description;
  }

  const internalExperience = buildInternalExperiencesDescription(
    profile.ki_projects
  );
  const externalExperience = buildExternalExperiencesDescription(
    profile.experiences_outside_ki
  );

  return `${internalExperience}\n\n${externalExperience}`;
};

export const buildNameSurname = (profile: Employee): string =>
  `${profile.first_name} ${profile.second_name}`;

export const buildJobTitle = (profile: Employee): string =>
  `${profile.seniority} ${profile.job_title}`;

export const buildEducation = (profile: Employee): string => {
  if (!profile.education?.length) {
    return '';
  }

  return profile.education
    .map(
      (e) =>
        `${e.institution_name}\n${
          e.education.study_field_name
        }\n${buildDateText(e.started, e.ended)}`
    )
    .join('\n\n');
};

const buildInternalExperiencesFormatted = (
  experiences: EmployeeInternalExperience[] = []
): PptTextProps[] => {
  const experiencesTextProps: PptTextProps[] = [];

  if (!experiences.length) {
    return experiencesTextProps;
  }

  try {
    experiences.forEach((e) => {
      experiencesTextProps.push({
        text: `${buildDateText(e.start_at, e.end_at)}\n${e.role}\n${
          e.project.sms_title
        }\n\n`,
        options: { bold: true },
      });
      experiencesTextProps.push({
        text: `${stripHtml(e.project.description)}\n\n${stripHtml(
          e.tasks
        )}\n\n`,
        options: { fontSize: 7 },
      });
    });

    return experiencesTextProps;
  } catch (error) {
    console.warn(`Fail generating KI Experiences Detailed: ${error}`);
    return [{ text: 'Fail generating KI Experiences\n\n' }];
  }
};

const buildExternalExperiencesFormatted = (
  experiences: EmployeeExternalExperience[] = []
): PptTextProps[] => {
  const experiencesTextProps: PptTextProps[] = [];

  if (!experiences.length) {
    return experiencesTextProps;
  }

  try {
    experiences.forEach((e) => {
      experiencesTextProps.push({
        text: `${buildDateText(e.start_date, e.end_date)}\n${e.title}\n${
          e.type
        }\n`,
        options: { bold: true },
      });
      experiencesTextProps.push({
        text: `${stripHtml(e.description)}\n\n`,
        options: { fontSize: 7 },
      });
    });

    return experiencesTextProps;
  } catch (error) {
    console.warn(`Fail generating Outside KI Experiences Detailed: ${error}`);

    return [{ text: 'Fail generating Outside KI Experiences\n\n' }];
  }
};

export const buildExperiences = (profile: Employee): PptTextProps[] => {
  const internalExperiences = buildInternalExperiencesFormatted(
    profile.ki_projects
  );
  const externalExperiences = buildExternalExperiencesFormatted(
    profile.experiences_outside_ki
  );

  return [...internalExperiences, ...externalExperiences];
};

export const buildIndustries = (profile: Employee): string => {
  if (!profile.industries?.length) {
    return '';
  }

  return profile.industries.map((i) => i.industry.name).join('\n');
};

const getLevelText = (levelId: LanguageProficiency) =>
  LanguageProficiencyText[levelId as LanguageProficiency];

export const buildLanguages = (profile: Employee): string => {
  if (!profile.languages?.length) {
    return '';
  }

  const orderedLangs = orderEmployeeLanguagesByProficiency(profile.languages);

  return orderedLangs
    .map((l) => `${l.language.name}, ${getLevelText(l.language_level_id)}`)
    .join('\n');
};

export const addSection = (ppt: Ppt, title: string) => {
  ppt.addSection({ title });
};

export const fillAvatar = async ({
  fill,
  url,
  ...props
}: PptFillAvatarProps) => {
  const path = await loadAzureBlob(url);

  fill({ path, ...props });
};
